import shad from './shadow.svg';
import React, { useState } from 'react'
import { Wheel } from 'react-custom-roulette'
import './App.css';


const data = [
  { option: '5000' },
  { option: '0' },
  { option: '400' },
  { option: '0' },
  { option: '600' },
  { option: '0' },
  { option: '1000' },
  { option: '0' },
  { option: '2500' },
  { option: '0' },
]

const rouletteColors = ['#47AAF5', '#fff']

const App = ({ id, go, fetchedUser }) => {
    
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(2);
  const [countScroll, setCountScroll] = useState(3);
  const [butStat, setButStat] = useState(false);
  const [balance, setBalance] = useState('0₽');

  const goSpin = () => {
	setMustSpin(true)
  }

  const handleSpinClick = () => {
	setCountScroll(countScroll - 1)
	console.log(countScroll)
	if (countScroll == 3) {
		const newPrizeNumber = 9
		setPrizeNumber(newPrizeNumber)
		setBalance('400₽')
	} 
	else{
		if (countScroll == 2) {
			const newPrizeNumber = 4
			setPrizeNumber(newPrizeNumber)
			
		} else{
			setButStat(true)
			setBalance('1000₽')
			go('final')
		}
		
	}
    
    
    setMustSpin(false)
  }
  
  

  return (
    <div className="App">
   
			<div className='header'>
        <p className='head-title'>Твоя удача</p>
        <div className='head-balance-block'>
          <p className='head-balance-text'>Твой баланс</p>
          <p className='head-balance'>{balance}</p>
				</div>
			</div>

      <div className='block-adaptive'>
        <div className='roullet-shadow-block'>
          <Wheel
            mustStartSpinning={mustSpin}
            prizeNumber={prizeNumber}
            data={data}
            backgroundColors={rouletteColors}
            outerBorderColor='#50B3FF'
            outerBorderWidth = {[20]}
            innerRadius = {[8]}
            innerBorderColor = '#5485fe'
            innerBorderWidth = {[0]}
            radiusLineColor='#5485fe'
            radiusLineWidth={[0]}
            spinDuration={[0.44]}
            textDistance={[70]}
            textColors={['#ffffff', '#47AAF5']}
            fontSize={[25]}
            fontFamily='Montserrat'
            onStopSpinning={handleSpinClick}
          />
          {/* <img className='shadow-image' src={shad}/> */}
        </div> 

        
          <div className='scroll-count-block-wrapper'>
            <div className='scroll-count-block'>
              <p className='scroll-text'>Попыток</p>
              <p className='scroll-count'>{countScroll}</p>
              <div className='scroll-line'></div>
            </div>
            <button disabled={butStat} onClick={goSpin} className="btn-rotate">Крутить</button>
            
          </div>
      </div>
			
  </div>
  )

  
  
}

export default App;
